/* eslint-disable */
let endTime = null;
let totalTime = null;
let running = false
let offset = 0;
let calcInterval = null;

console.log("⏱👷 Timer Worker Inicializado **");

self.onmessage = (msg) => {

  console.log('⏱👷 worker onmessage', msg.data)
  if(msg.data.endTime){
    endTime = msg.data.endTime;
    totalTime = endTime - Date.now();
  }

  running = !(msg.data.pause ?? false);
  offset = msg.data.offset ?? 0;

  if(running) {
    calcInterval = setInterval(CalcCountdown, 10)
  } else {
    clearInterval(calcInterval)
  }

};

function CalcCountdown() {

    if (!running || endTime == null) return
    const now = Date.now();
    const remaining = endTime - now;

    let timeString = "00:00";
    let timeNormalized = 0;
    
    if (remaining > 0) {      
      timeString = convertMsToTime(remaining);
      timeNormalized = remaining / totalTime;
    }

    if (remaining >= 0) {
      self.postMessage({ timeString, timeNormalized });
    } else {
      running = false;
      clearInterval(calcInterval)
    }
  
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function convertMsToTime(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  // let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  // hours = hours % 24;

  return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
}